<template>
    <div class="certificateView">
        <div style="width: 100%">
            <img src="~@/assets/img/TechnicalCollege/43.png" alt="" />
        </div>
        <div style="width: 100%; background: #ffffff">
            <news class="banner" />
        </div>
        <div style="width: 100%; background: #f0f4f8">
            <div class="itembox">
                <div class="til">
                    <img src="~@/assets/img/TechnicalCollege/73.png" alt="" />
                    <div>2+2留学/2+3留学必读</div>
                    <img src="~@/assets/img/TechnicalCollege/74.png" alt="" />
                </div>
                <div class="item_con">
                    <div>
                        <div class="item_con_til">
                            <div style="position: relative; z-index: 99; font-weight: bold">
                                什么是2+2留学？
                            </div>
                            <div class="line"></div>
                        </div>
                        <div class="item_con_text">
                            2+2留学是本科留学项目： 学生在国内大学全日制学习两年，然后再转去国外大学学习两年的本科课程，毕业后取得国外大学的学士学位。</div>
                    </div>
                    <div>
                        <div class="item_con_til">
                            <div style="position: relative; z-index: 99; font-weight: bold">
                                什么是2+3留学？
                            </div>
                            <div class="line"></div>
                        </div>
                        <div class="item_con_text">
                            2+3留学是本科与硕士连读项目： 学生在国内大学全日制学习两年，然后再转去国外大学学习两年的本科课程和一年的硕士课程，毕业后取得国外大学的学士学位和硕士学位。</div>
                    </div>
                    <div>
                        <div class="item_con_til">
                            <div style="position: relative; z-index: 99; font-weight: bold">
                                适合人群
                            </div>
                            <div class="line"></div>
                        </div>
                        <div class="item_con_text">
                            <p>1、会考结束的高二学生，有出国留 学计划的学生</p>
                            <p>2、高三在读学生，未来想要去国外 读本科课程的学生</p>
                            <p>3、高三毕业生，想要通过出国留学提升学历与能力的学生</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%; background: #ffffff">
            <div class="itembox">
                <div class="til">
                    <img src="~@/assets/img/TechnicalCollege/73.png" alt="" />
                    <div>2+2留学/2+3留学必读</div>
                    <img src="~@/assets/img/TechnicalCollege/74.png" alt="" />
                </div>
                <div class="itembox_con">
                    <div>
                        <div class="itembox_con_til">入学门槛较低</div>
                        <div class="line"></div>
                        <div class="itembox_con_text">
                            应届高中毕业生及同等学历者 可以申请2+2留学项目。学生只
                            需要高考成绩达到本科分数线 以上，或者通过学校组织的入
                            学测试，即可顺利入学。
                        </div>
                    </div>
                    <div>
                        <div class="itembox_con_til">节省留学资金</div>
                        <div style="background: #3773c7" class="line"></div>
                        <div class="itembox_con_text">
                            相较于直申留学昂贵的费用， 此项目学生由于在国内学习三年的
                            时间，可节省40万左右的留学费用。 学生在国内院校期间所享受的
                            待遇，与该校的统招生 是完全一样的。
                        </div>
                    </div>
                    <div>
                        <div class="itembox_con_til">学位含金量高</div>
                        <div style="background: #89ba3f" class="line"></div>
                        <div class="itembox_con_text">
                            学生毕业后获得的学位含金量 非常高，和国外的本土大学生的学
                            位完全一样。学生回国之后， 还可以登录留学服务政务平台，
                            进行在线学历认证。
                        </div>
                    </div>
                    <div>
                        <div class="itembox_con_til">留学缓冲期长</div>
                        <div style="background: #f3c055" class="line"></div>
                        <div class="itembox_con_text">
                            此项目的学生拥有三年的留学 缓冲期，能够提前适应国外大学
                            教学模式。对于那些综合成绩基础 不太理想的学生来说，是提高
                            个人综合竞争力与学习成 绩的良好契机。
                        </div>
                    </div>
                    <div>
                        <div class="itembox_con_til">办学安全可靠</div>
                        <div style="background: #ed706b" class="line"></div>
                        <div class="itembox_con_text">
                            大部分2+2留学、2+3留学项目由 国内一流大学、教育部留学服务
                            中心和海外大学共同合作办学。 项目由官方办学，安全可靠，
                            广大学生和家长可以 放心报名。
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bgg" style="width: 100%">
            <div style="width: 1150px;" class="itembox">
                <div class="til">
                    <img src="~@/assets/img/TechnicalCollege/73.png" alt="" />
                    <div>2+2留学/2+3留学必读</div>
                    <img src="~@/assets/img/TechnicalCollege/74.png" alt="" />
                </div>
                <div class="itembox_connect">
                    <div @mouseenter="onhover(1)" @mouseleave="whichColor = 0" class="i_con1">
                        <div>
                            <img v-if="whichColor == 1" src="~@/assets/img/TechnicalCollege/80.png" alt="" />
                            <img v-else src="~@/assets/img/TechnicalCollege/90.png" alt="" />
                        </div>
                        <div>
                            学生根据自己的情况 和未来职业方向选择 3+2项目。通过入学
                            测试，进入大学学习。
                        </div>
                    </div>
                    <div @mouseenter="onhover(2)" @mouseleave="whichColor = 0" class="i_con2">
                        <div>
                            <img v-if="whichColor == 2" src="~@/assets/img/TechnicalCollege/91.png" alt="" />
                            <img v-else src="~@/assets/img/TechnicalCollege/82.png" alt="" />
                        </div>
                        <div>
                            学生入学后第一年， 主要学习英语课程 以及专业学分课程，
                            为出国留学打好语 言基础。
                        </div>
                    </div>
                    <div @mouseenter="onhover(3)" @mouseleave="whichColor = 0" class="i_con3">
                        <div>
                            <img v-if="whichColor == 3" src="~@/assets/img/TechnicalCollege/92.png" alt="" />
                            <img v-else src="~@/assets/img/TechnicalCollege/83.png" alt="" />
                        </div>
                        <div>
                            除了英语课程、专 业学分课程的学习 之外，学生需要开
                            始学习学习专业实 训课程。
                        </div>
                    </div>
                    <div @mouseenter="onhover(4)" @mouseleave="whichColor = 0" class="i_con4">
                        <div>
                            <img v-if="whichColor == 4" src="~@/assets/img/TechnicalCollege/87.png" alt="" />
                            <img v-else src="~@/assets/img/TechnicalCollege/84.png" alt="" />
                        </div>
                        <div>
                            当学生的综合成绩 达到国外大学录取 要求时，3+2留学 项目老师会协助学
                            生进行海外留学手 续办理。
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div style="padding-bottom: 20px;">
            <academy></academy>
        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
// import banners from "./banner.vue";
import news from "./news.vue";
// import major from "./major.vue";
import academy from "./academy.vue";

export default {
    components: { news, academy },

    data() {
        return {
            id: "",
            countryList: [],
            mList: [],
            whichColor: 1
        };
    },
    created() {
        this.id = localStorage.getItem("projectTypeId");
    },
    mounted() { },
    methods: {
        onhover(i) {
            this.whichColor = i
        }
    },
};
</script>

<style lang="less" scoped>
.itembox {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0;

    .til {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
        font-weight: normal;
        font-size: 40px;
        color: #444444;
        font-weight: bold;

        img {
            width: 122px;
            height: 54px;
        }

        >div {
            margin: 0 28px;
        }
    }

    .item_con {
        display: flex;
        margin-top: 50px;
        justify-content: space-between;

        >div {
            width: 364px;
            height: 436px;
            background: url("~@/assets/img/TechnicalCollege/75.png");
            background-size: 100% 100%;
            // border-radius: 100px 6px 6px 6px;
        }

        >div:nth-child(2) {
            background: url("~@/assets/img/TechnicalCollege/76.png");
            background-size: 100% 100%;
        }

        >div:nth-child(3) {
            background: url("~@/assets/img/TechnicalCollege/77.png");
            background-size: 100% 100%;
        }

        .item_con_til {
            position: relative;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: normal;
            font-size: 24px;
            color: #333333;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 22px;

            .line {
                position: absolute;
                bottom: -5px;
                width: 192px;
                height: 17px;
                background: linear-gradient(90deg, #705ad5 0%, #ffc4af 100%);
                border-radius: 0px 0px 0px 0px;
            }
        }

        .item_con_text {
            width: 300px;
            height: 210px;
            font-family: PingFangSC-Regular, PingFangSC-Regular;
            font-weight: normal;
            font-size: 20px;
            color: #666666;
            line-height: 30px;
            margin: 0 auto;
            margin-top: 24px;
        }
    }

    .itembox_con {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >div {
            width: 220px;
            height: 220px;
            background: #ffffff;
            border: 2px solid #164694;
            border-radius: 50%;
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: transform 1.5s;
            cursor: pointer;

            .itembox_con_til {
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 20px;
                color: #333333;
                text-align: center;
                margin-top: 40px;
            }

            .line {
                width: 26px;
                height: 3px;
                background: #164694;
                border-radius: 0px 0px 0px 0px;
                margin: 8px auto;
            }

            .itembox_con_text {
                width: 180px;
                height: 90px;
                font-family: PingFangSC-Regular, PingFangSC-Regular;
                font-weight: normal;
                font-size: 13px;
                color: #999999;
                text-align: center;
                font-style: normal;
                text-transform: none;
            }

            &:nth-child(2) {
                border: 2px solid #3773c7;
            }

            &:nth-child(3) {
                border: 2px solid #89ba3f;
            }

            &:nth-child(4) {
                border: 2px solid #f3c055;
            }

            &:nth-child(5) {
                border: 2px solid #ed706b;
            }

            &:hover {
                transform: rotateY(360deg);
            }
        }
    }

    .itembox_connect {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        margin-top: 50px;

        >div {
            width: 260px;

            font-family: PingFangSC-Regular, PingFangSC-Regular;
            font-weight: normal;
            font-size: 16px;
            color: #333333;
            line-height: 26px;
            background: #ffffff;
            border-radius: 6px 6px 6px 6px;
            padding: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            >div:nth-child(2) {
                margin-top: 20px;
            }

            img {
                width: 110px;
                // height: 40px;
            }

            &:hover {
                color: #FFFFFF;
            }
        }

        .i_con1 {

            height: 210px;

            &:hover {
                background: #164694;
            }
        }

        .i_con2 {

            height: 230px;

            &:hover {
                background: #3773C7;
            }
        }

        .i_con3 {

            height: 260px;

            &:hover {
                background: #89BA3F;
            }
        }

        .i_con4 {
            height: 290px;

            &:hover {
                background: #F3C055;
            }
        }

        .i_con5 {
            height: 320px;

            &:hover {
                background: #ED706B;
            }
        }

        .i_con6 {
            height: 350px;

            &:hover {
                background: #C971EE;
            }
        }

        .line {
            position: absolute;
            width: 1042px;
            height: 338px;
            background: transparent;
            z-index: 99;
            left: 65px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.bgg {
    background: url("~@/assets/img/TechnicalCollege/78.png");
    background-size: 100% 100%;
}

img {
    width: 100%;
}
</style>